import { useRouter } from "next/router";
import { useEffect } from "react";

export default function NotFoundPage() {
  const { replace, asPath } = useRouter();

  useEffect(() => {
    const query = asPath.split("?")[1];
    replace("/" + (query ? `?${query}` : ""));
  }, [replace, asPath]);

  return null;
}
